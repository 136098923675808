<template>
<div class="card-box-full">
  <div class="card-box-header">
    Explain
  </div>

    <div class="form-group row" style="margin-top: 15px; padding: 5px">
      <div class="col-md-12">
        <div class="input-group">

          <input class="form-control" v-model="message" autocomplete="on" placeholder="Enter a message" :disabled="isExecuting" @keyup.enter="explain()">
          <div class="input-group-append" color="primary">
            <CButton @click="explain()" color="primary" :disabled="isExecuting" style="width: 80px">
              <div v-if="isExecuting" class="spinner-border spinner-border-sm text-light" role="status" style="">
                <span class="sr-only"></span>
              </div>
              <div v-else>
                Explain
              </div>
            </CButton>
          </div>
        </div>

        <!-- ----------------------------------------------------------------- -->


        <!-- ----------------------------------------------------------------- -->
        <div v-if="errorMessage">
          <div class="alert alert-danger" style="margin: 15px 0px 0px 0px" role="alert">{{ errorMessage }}</div>
        </div>

        <!-- ----------------------------------------------------------------- -->
        <div v-if="words.length > 0">
          <hr>

          <div class="rounded-box">
            <h6>Step 1</h6>
            <div style="padding-left: 5px">
              <p>Convert confusable characters in the message (ex: 𝖍𝖊𝖑𝖑𝖔 would convert to <b>hello</b>)</p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">{{ confusables }}</div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 2</h6>
            <div style="padding-left: 5px">
              <p>Split the message into words</p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">
                <span v-for="word in words" class="badge badge-pill badge-info" style="margin-right: 2px">
                  {{ word }}
                </span>
              </div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 3</h6>
            <div style="padding-left: 5px">
              <p>Clean the words by lowercasing and converting words like <b>žůžo</b> to <b>zuzo</b></p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">
                <span v-for="word in cleanedWords" class="badge badge-pill badge-info" style="margin-right: 2px">
                  {{ word }}
                </span>
              </div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 4</h6>
            <div style="padding-left: 5px">
              <p>Normalize the words by converting <b>O</b>'s to <b>0</b>'s, <b>1</b>'s to <b>i</b>'s, and <b>L</b>'s to <b>i</b>'s</p>
              <p>Remove non-alphanumeric characters</p>
              <p v-html="'Convert any URLs to <b>{{URL}}</b> and any numbers to <b>{{NUMBER}}</b>'"></p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">
                <span v-for="word in normalizedWords">
                  <span v-if="word.startsWith('{{') && word.endsWith('}}')" class="badge badge-pill badge-danger" style="margin-right: 2px">
                    {{ word }}
                  </span>
                  <span v-else class=" badge badge-pill badge-info" style="margin-right: 2px">
                    {{ word }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 5</h6>
            <div style="padding-left: 5px">
              <p>Remove consecutive occurrences of the same word</p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">
                <span v-for="word in dedupedWords">
                  <span v-if="word.startsWith('{{') && word.endsWith('}}')" class="badge badge-pill badge-danger" style="margin-right: 2px">
                    {{ word }}
                  </span>
                  <span v-else class=" badge badge-pill badge-info" style="margin-right: 2px">
                    {{ word }}
                  </span>
                </span>
              </div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 6</h6>
            <div style="padding-left: 5px">
              <p>Create the fingerprint vectors <i>(up to 50)</i> by generating a hash using a sliding window of 3 words</p>
              <div class="alert alert-info" style="margin: 15px 0px 0px 0px" role="alert">
                <table>
                  <thead>
                    <tr style="font-size: 13px; border: 2px solid transparent">
                      <th style="text-align:right">Input</th>
                      <th></th>
                      <th style="text-align:left">Output</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="vector in vectors">
                      <td align="right"><span class="badge badge-pill badge-dark">{{ vector.input }}</span></td>
                      <td width="20" align="center">=</td>
                      <td align="left"><span class="badge badge-pill badge-info">{{ vector.output }}</span></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="rounded-box" style="margin-top: 10px">
            <h6>Step 7</h6>
            <div style="padding-left: 5px">
              <p>Search the database for messages that match at least 70% of these vectors, in the same order</p>
              <p>If a match is made, grab the matched messages fingerprint which is the message UUID</p>
            </div>
          </div>

        </div>

      </div>
    </div>


</div>
</template>

<script>
export default {
  name: 'Explain',
  data() {
    return {
      cleanedWords: [],
      confusables: "",
      dedupedWords: [],
      errorMessage: null,
      isExecuting: false,
      message: "𝕳𝖊𝖑𝖑𝖔! 𝕳𝖊𝖑𝖑𝖔 𝖞𝖔𝖚 𝖜𝖔𝖓 $2̾0̾0̾ 𝖙𝖔 ¢låïm 𝖛𝖎𝖘𝖎𝖙 𝖊𝖝𝖆𝖒𝖕𝖑𝖊.𝖈𝖔𝖒",
      normalizedWords: [],
      vectors: [],
      words: []
    }
  },
  methods: {
    //--------------------------------------------------------------------------
    parseMessage(body) {
      try {
        var result = JSON.parse(body);
        return result.message;
      } catch (err) {
        return "";
      }
    },
    //--------------------------------------------------------------------------
    processResponse(response) {
      this.cleanedWords = response.cleaned_words;
      this.confusables = response.confusables;
      this.dedupedWords = response.deduped_words;
      this.normalizedWords = response.normalized_words;
      this.vectors = response.vectors;
      this.words = response.words;
    },
    //--------------------------------------------------------------------------
    explain() {

      // Make sure we have a message
      if (!this.message) {
        return;
      }

      // Make sure we're not currently executing
      if (this.isExecuting) {
        return;
      } else {
        this.isExecuting = true;
        this.errorMessage = null;
        this.words = [];
      }

      var that = this;
      var method = "POST";
      var body = JSON.stringify({
        message: this.message
      });
      var url = "https://sms-fingerprint.platform.robocalls.ai/explain";
      var requiresSession = true;
      RobocallsAI.shared().execute(method, url, body, requiresSession, {
        onSuccess: function(code, body) {
          // Code = HTTP status code
          // Body = Response from server
          that.isExecuting = false;
          if (code != 200) {
            // Something went wrong
            var message = that.parseMessage(body);
            if (message) {
              that.errorMessage = message;
            } else {
              that.errorMessage = "Received a code " + code + " from the service";
            }
          } else {
            // Parse the accounts
            try {
              var result = JSON.parse(body);
              that.processResponse(result);
            } catch (err) {
              that.errorMessage = "Failed to parse response from the server";
            }
          }
        },
        onCancelled: function() {
          // Fired if the request was cancelled
          that.isExecuting = false;
        },
        onFailure: function(error) {
          // Fired if the request fails
          that.isExecuting = false;
          that.errorMessage = error;
        }
      })
    },
  },
  mounted() {}
}
</script>
